import { InputSearch } from '@/components/form/inputs/InputSearch';
import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenuHeader } from '@/components/sidebars/context/ContextSidebarMenuHeader';
import { ContextSidebarMenuTileLink } from '@/components/sidebars/context/ContextSidebarMenuTileLink';
import { useValue } from '@/lib/composables';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu, IMyUserFragment_profissionais } from '@/typings';
import { computed } from '@vue/composition-api';

export default createComponent({
  name: 'AgendaProfissionaisSidebar',
  setup(props, ctx) {
    const { $user } = useAuthInfo();

    const $profissionais = computed<IMyUserFragment_profissionais[]>(
      () => $user.value?.profissionais || [],
    );

    const [$filterValue] = useValue('');

    const $filteredProfissionais = computed(() => {
      if ($filterValue.value) {
        return $profissionais.value.filter(v =>
          v.nome.toLowerCase().includes($filterValue.value.toLowerCase()),
        );
      }
      return $profissionais.value;
    });

    const $menus = computed<IMenu[]>(() =>
      $filteredProfissionais.value.map(v => ({
        text: v.nome,
        icon: MyIcons.userAccount,
        imageUrl: v.imagem?.thumbnailUrl || v.imagem?.url,
        to: Routes.app.agenda(v.id).index,
        noExact: true,
      })),
    );

    return () => (
      <ContextSidebar>
        <v-list>
          <ContextSidebarMenuHeader title="Agenda" />

          <div class="">
            <InputSearch
              placeholder="Filtrar médicos..."
              v-model={$filterValue.value}
            />
          </div>

          <v-divider class="mb-4" />

          {$menus.value.map((menu, index) => (
            <ContextSidebarMenuTileLink key={index} menu={menu} />
          ))}
        </v-list>
      </ContextSidebar>
    );
  },
});
